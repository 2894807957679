import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useSearchQuery from 'queries/useSearchQuery';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import Events from '@activebrands/core-web/libs/Events';
import { ProductEvents } from '@activebrands/core-web/libs/Events/types';
import { searchMultiQuery } from '@activebrands/core-web/libs/algolia';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import parseSearchItem from '@activebrands/core-web/utils/parse-search-item';
import { updateQueryParams } from '@activebrands/core-web/utils/query';
import stringReplace from '@grebban/utils/string/replaceSubStrings';
import CategoryListing from 'components/Header/SearchOverlay/CategoryListing';
import OverlaySearchInput from 'components/Header/SearchOverlay/OverlaySearchInput';
import SearchListing from 'components/Header/SearchOverlay/SearchListing';
import Loading from 'components/Loading';
import SearchTypeFilterButtons from 'components/SearchTypeFilterButtons';
import ThemeButton from 'components/buttons/ThemeButton';
import StateIcon from 'components/icons/StateIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import Cookies from 'js-cookie';
import SearchHistoryListing from './SearchHistoryListing';

const LANG_CODE = process.env.REACT_APP_ALGOLIA_LANGUAGE_CODE || '';

const Wrapper = styled('div', {
    position: 'relative',
    background: 'var(--color-bg-header)',
    margin: '4px',
    width: 'var(--width-overlays)',
    height: `calc(var(--vh) - var(--height-header) - 8px)`, //8px since there is no gap around header on mobile
    overflow: 'hidden',

    [media.min['desktop.sm']]: {
        margin: '8px 4px',
        height: `calc(var(--vh) - var(--height-header) - 12px)`,
    },
});

const OverlayHeader = styled('div', {
    display: 'flex',
    minHeight: 'var(--height-header)',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 12px',
    borderBottom: '1px solid var(--color-border-search-overlay)',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
});

const InnerWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 67px)', // result button
    margin: '12px',
});

const NoResults = styled('span', {
    display: 'block',
    marginBottom: '24px',
    color: 'var(--color-text-error)',
});

const Footer = styled('div', {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '12px',
    background: 'var(--color-bg-header)',
    borderTop: '1px solid var(--color-border-search-overlay)',
    zIndex: 2,
});

const initialState = {
    loading: false,
    category: {
        totalHits: 0,
        hits: [],
        metaData: {},
    },
    posts: {
        totalHits: 0,
        hits: [],
        metaData: {},
    },
    products: {
        totalHits: 0,
        hits: [],
        metaData: {},
    },
};

const SearchOverlay = ({ previewDataOverride }) => {
    const [state, setState] = useState({
        ...initialState,
        noResult: [],
        filter: 'products',
        activeFilter: fm('Products'),
        searchHistory: [],
    });

    // Access the searchstring from the SearchProvider
    const [searchstring] = useStateContext();
    const { searchPage, headerConfigContent } = useSearchQuery();
    const { searchInput, searchNoResultLabel, searchNoResultText, searchNoResultTextActiveFilter } =
        previewDataOverride || headerConfigContent;

    const showType = state.filter === 'posts' ? 'posts' : 'products';
    const searchResult = state[showType].hits;
    const filteredResult = searchResult?.filter(
        item => ['products', 'posts'].includes(state.filter) || item.categories?.includes(state.filter)
    );

    const { totalHits } = state[showType];
    const { metaData } = state[showType];

    const searchTextVariables = {
        '{totalHits}': totalHits,
        '{activeFilter}': state.activeFilter,
        '{searchstring}': searchstring,
    };

    const shopConfig = useSelector(state => state.application.shopConfig);
    const { marketId, pricelistId } = shopConfig;

    // Used for filtering out non relevant teamstore products
    const auth = useSelector(state => state.auth) || {};
    const teamId = (auth.isAuthenticated && auth?.user?.teams?.[0]?.tag) || 'not_teamstore';

    // Trigger a search when searchstring changes
    useEffect(async () => {
        if (searchstring !== undefined) {
            // Get current search history from cookies
            const currentSearchHistory = JSON.parse(Cookies.get('searchHistory') || '[]');
            // Set latest search string to search history (max three at a time)
            if (searchstring && !currentSearchHistory.includes(searchstring)) {
                const newSearchHistory = JSON.stringify([searchstring, ...currentSearchHistory].slice(0, 3));
                Cookies.set('searchHistory', newSearchHistory, { expires: 7 });
            }

            setState(prevState => ({
                ...prevState,
                loading: true,
            }));

            const responses = await searchMultiQuery([
                {
                    // Productsearch
                    indexName: `products_${LANG_CODE}`,
                    query: searchstring,
                    params: {
                        filters: [
                            `_pricelists:${pricelistId}`,
                            `_marketplaces:${marketId}`,
                            `teamstore_id:${'not_teamstore'} OR teamstore_id:${teamId}`,
                        ],
                    },
                },
                {
                    // Storyblok category search
                    indexName: `pages_${LANG_CODE}`,
                    query: searchstring,
                    params: {
                        hitsPerPage: 5,
                        filters: 'type:categoryPage',
                    },
                },
                {
                    // TODO: fix algolia setup
                    // Storyblok articles search
                    indexName: `pages_${LANG_CODE}`,
                    query: searchstring,
                    params: {
                        filters: 'type:articlePage',
                    },
                },
            ]);

            // Save data for after a search
            if (searchstring && Array.isArray(responses)) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    products: {
                        totalHits: responses[0].nbHits,
                        hits: responses[0].hits.map(item => parseSearchItem(item, pricelistId, marketId)),
                        metaData: {
                            index: responses[0].index,
                            queryID: responses[0].queryID,
                        },
                    },
                    category: {
                        totalHits: responses[1].nbHits,
                        hits: responses[1].hits.map(parseSearchItem),
                        metaData: {
                            index: responses[1].index,
                            queryID: responses[1].queryID,
                        },
                    },
                    posts: {
                        totalHits: responses[2].nbHits,
                        hits: responses[2].hits.map(parseSearchItem),
                        metaData: {
                            index: responses[2].index,
                            queryID: responses[2].queryID,
                        },
                    },
                }));

                Events.trigger(ProductEvents.SEARCH, {
                    search_term: searchstring,
                });

                // Reset data, but add the empty search results to "most popular"
            } else {
                setState(prevState => ({
                    ...prevState,
                    ...initialState,
                }));
            }
        }
    }, [JSON.stringify(searchstring)]);

    // Will navigate to search page and trigger a search there with a http-query
    const triggerSearchPageSearch = () => {
        overlay.close('search');

        // Add searchstring to query
        const params = {
            q: searchstring,
        };

        // Add type if set to posts, products is default
        if (showType === 'posts') {
            params.t = 'posts';
        }

        // Update the http-query, this will trigger a search on the search page
        updateQueryParams(params, { basePath: searchPage });
    };

    // Will update what kind of content that will be displayed, will not trigger a search
    const setFilter = (name, id) => {
        setState(prevState => ({
            ...prevState,
            filter: id,
            activeFilter: name,
        }));
    };

    // List with all available filter types
    const filterButtons = [
        {
            id: 'products',
            label: fm('Products'),
            totalHits: state.products.totalHits,
            onClick: () => setFilter(fm('Products'), 'products'),
        },
        {
            id: 'posts',
            label: fm('Stories'),
            totalHits: state.posts.totalHits,
            onClick: () => setFilter(fm('Stories'), 'posts'),
        },
    ];

    let categoryHits = state.category.hits;
    const excludedFilters = ['posts', 'products'];

    // Modifies the array to only items that match the active filter, unless one of the excludes filters
    if (excludedFilters.indexOf(state.filter) === -1) {
        categoryHits = state.category.hits.filter(item => item.tag === state.activeFilter);
    }

    const disabledStyles = !totalHits
        ? {
              backgroundColor: 'var(--color-bg-button-inverted-disabled)',
              color: 'var(--color-text-button-inverted-disabled)',
              cursor: 'not-allowed',
              borderStyle: 'none',
          }
        : {};

    return (
        <Wrapper>
            <OverlayHeader>
                <Heading $style={{ color: 'var(--color-text-search-header)' }} as="h3" fontKeys="Primary/16_100_-05">
                    {fm('Search')}
                </Heading>
                <StateIcon
                    $style={{ cursor: 'pointer' }}
                    size="16px"
                    state="close"
                    onClick={() => overlay.close('search')}
                />
            </OverlayHeader>
            <OverlaySearchInput placeholder={searchInput} />
            <InnerWrapper>
                {!searchstring && !filteredResult.length && <SearchHistoryListing />}
                <ScrollableBox $style={{ flexGrow: 1 }}>
                    {state.loading ? (
                        <Loading />
                    ) : (
                        <>
                            {!filteredResult.length ? (
                                <>
                                    {searchstring && (
                                        <NoResults>
                                            {/* TODO: Restore when users can search for posts */}
                                            {/* <SearchTypeFilterButtons
                                                $style={{ marginBottom: '28px' }}
                                                buttonStyle={{ flexGrow: 1 }}
                                                current={state.filter}
                                                items={filterButtons}
                                            /> */}
                                            <div>
                                                {searchNoResultLabel && (
                                                    <Heading
                                                        $style={{ marginBottom: '12px' }}
                                                        fontKeys="Primary/22_120_-05"
                                                    >
                                                        {searchNoResultLabel}
                                                    </Heading>
                                                )}
                                                {searchNoResultText && (
                                                    <Paragraph fontKeys="Primary/14_140_-2">
                                                        {!totalHits
                                                            ? stringReplace(searchNoResultText, searchTextVariables)
                                                            : stringReplace(
                                                                  searchNoResultTextActiveFilter,
                                                                  searchTextVariables
                                                              )}
                                                        {}
                                                    </Paragraph>
                                                )}
                                            </div>
                                        </NoResults>
                                    )}
                                    <SearchListing items={state.noResult} label={fm('Most popular')} type="toplist" />
                                </>
                            ) : (
                                <>
                                    {showType === 'products' && categoryHits.length > 0 && (
                                        <>
                                            <Heading
                                                $style={{ color: 'var(--color-text-subtle)' }}
                                                as="h4"
                                                fontKeys="Primary/12_100_-1"
                                            >
                                                {fm('Categories')}
                                            </Heading>
                                            <CategoryListing
                                                items={categoryHits}
                                                searchMeta={state.category.metaData}
                                            />
                                        </>
                                    )}
                                    <SearchTypeFilterButtons
                                        $style={{ marginBottom: '28px' }}
                                        buttonStyle={{ flexGrow: 1 }}
                                        current={state.filter}
                                        items={filterButtons}
                                    />
                                    <SearchListing items={filteredResult} searchMeta={metaData} />
                                </>
                            )}
                        </>
                    )}
                </ScrollableBox>
            </InnerWrapper>
            <Footer>
                <ThemeButton
                    $style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: 'var(--color-bg-search-button)',

                        // Replicate disabled style on a link
                        ...disabledStyles,
                    }}
                    disabled={!totalHits}
                    // Used for crawling-purposes (seo)
                    // href={addLocalePrefix(searchPage)}
                    fontKeys="Primary/16_100_-05"
                    size="md"
                    theme="inverted"
                    onClick={e => {
                        // Prevent the regular link, use javascript to navigate instead
                        e.preventDefault();
                        if (totalHits) {
                            triggerSearchPageSearch();
                        }
                    }}
                >
                    {fm('See all results')}
                </ThemeButton>
            </Footer>
        </Wrapper>
    );
};

SearchOverlay.propTypes = {
    previewDataOverride: PropTypes.object,
};

SearchOverlay.defaultProps = {
    previewDataOverride: undefined,
};

export default SearchOverlay;
