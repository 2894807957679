import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSearchQuery from 'queries/useSearchQuery';
import { minBreakpoints } from '@activebrands/core-web/config/breakpoints';
import media from '@activebrands/core-web/config/media';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { updateQueryParams } from '@activebrands/core-web/utils/query';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { useLocation } from '@reach/router';
import ThemeButton from 'components/buttons/ThemeButton';
import SearchIcon from 'components/icons/SearchIcon';

const Form = styled('form', {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid var(--color-border-search-overlay)',
    paddingLeft: '12px',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
});

const StyledInput = styled('input', {
    width: '100%',
    height: '100%',
    padding: '8px 0',
    borderStyle: 'none',
    outline: 'none',
    borderRadius: 0,
    ...textStyles['Primary/16_100_-05'],

    '::placeholder': {
        color: 'var(--color-text-search-input-placeholder)',
    },
});

const OverlaySearchInput = ({ $style, placeholder }) => {
    const [, setState] = useStateContext();
    const [searchstring, setSearchstring] = useState();
    const location = useLocation();
    const { searchPage } = useSearchQuery();
    let delay;

    // Trigger a search by updating searchstring in the SearchProvider
    const triggerSearch = () => {
        setState(searchstring);
    };

    const onSubmit = e => {
        e.preventDefault();
        overlay.close('search');

        let params = {
            q: e.target.elements[0].value,
        };

        // Keep all params except page if the search is performed on the searchpage
        if (location.pathname === searchPage) {
            const queryParams = extractQueryParams(location.search);
            delete queryParams.page;
            params = { ...queryParams, ...params };
        }

        // Update the http-query, this will trigger a search on the search page
        updateQueryParams(params, { basePath: searchPage });
    };

    useEffect(() => {
        // Only trigger search below md
        if (window.innerWidth < minBreakpoints[3]) {
            delay = setTimeout(() => {
                triggerSearch();
            }, 500);
        }
        return () => {
            clearTimeout(delay);
        };
    }, [searchstring]);

    return (
        <Form $style={$style} onSubmit={onSubmit}>
            <StyledInput maxLength="100" placeholder={placeholder} onChange={e => setSearchstring(e.target.value)} />
            <ThemeButton
                $style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'auto',
                    height: 'auto',
                    padding: '16px 12px',
                    borderStyle: 'none',
                }}
                theme="default"
                type="submit"
            >
                <SearchIcon />
            </ThemeButton>
        </Form>
    );
};

OverlaySearchInput.propTypes = {
    $style: PropTypes.object,
    placeholder: PropTypes.string,
};

OverlaySearchInput.defaultProps = {
    placeholder: undefined,
};

export default OverlaySearchInput;
